import React from 'react';

export default function PricingSectionPricing2() {
    return (
        <React.Fragment>
            <>
                <section className="py-24 overflow-hidden">
  <div className="container px-4 mx-auto">
    <div className="mb-12 md:max-w-2xl text-center mx-auto">
      <span className="inline-block mb-4 text-sm text-green-400 font-medium tracking-tighter">Nightsable Card</span>
      <h2 className="mb-8 text-7xl lg:text-8xl text-white tracking-7xl lg:tracking-8xl">Compare our plans</h2>
      <p className="mb-12 text-gray-300 max-w-sm mx-auto">Nightsable is a strategic branding agency focused on brand creation, rebrands, and brand</p>
      <div className="flex flex-wrap justify-center -m-4">
        <div className="flex flex-wrap items-center w-auto p-4">
          <p className="text-white mr-8">Billed monthly</p>
          <div className="p-1 w-14 h-8 bg-gray-900 rounded-full">
            <div className="relative">
              <input className="custom-switch-1 opacity-0 absolute z-10 h-8 w-14 top-0 left-0" type="checkbox" />
              <div className="absolute left-0 transform w-6 h-6 bg-white rounded-full" />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap items-center w-auto p-4">
          <p className="text-gray-300 mr-3.5">Billed yearly</p>
          <span className="px-3 py-1 text-sm text-green-400 font-medium uppercase border border-green-400 rounded-full">Save 20%</span>
        </div>
      </div>
    </div>
    <div className="w-full overflow-x-auto overflow-y-hidden border border-gray-900 rounded-5xl">
      <div className="flex">
        <div className="flex-1">
          <div className="flex flex-wrap">
            <div className="w-full border-b border-r border-gray-900" style={{height: 240}} />
            <div className="flex items-end w-full h-24 px-8 py-6 border-b border-r border-gray-900">
              <p className="text-xl min-w-max font-medium text-white">Dropbox core features</p>
            </div>
            <div className="flex items-center w-full h-16 px-8 py-5 border-b border-r border-gray-900">
              <p className="mr-3.5 min-w-max text-white">Storage</p>
              <img src="nightsable-assets/images/pricing/info.png" alt="" />
            </div>
            <div className="flex items-center w-full h-16 px-8 py-5 border-b border-r border-gray-900">
              <p className="mr-3.5 min-w-max text-white">Users</p>
              <img src="nightsable-assets/images/pricing/info.png" alt="" />
            </div>
            <div className="flex items-center w-full h-16 px-8 py-5 border-b border-r border-gray-900">
              <p className="mr-3.5 min-w-max text-white">Best-in-class sync technology</p>
              <img src="nightsable-assets/images/pricing/info.png" alt="" />
            </div>
            <div className="flex items-center w-full h-16 px-8 py-5 border-b border-r border-gray-900">
              <p className="mr-3.5 min-w-max text-white">Anytime, anywhere access</p>
              <img src="nightsable-assets/images/pricing/info.png" alt="" />
            </div>
            <div className="flex items-center w-full h-16 px-8 py-5 border-b border-r border-gray-900">
              <p className="mr-3.5 min-w-max text-white">Easy and secure sharing</p>
              <img src="nightsable-assets/images/pricing/info.png" alt="" />
            </div>
            <div className="flex items-center w-full h-16 px-8 py-5 border-b border-r border-gray-900">
              <p className="mr-3.5 min-w-max text-white">256-bit AES and SSL/TLS encryption</p>
              <img src="nightsable-assets/images/pricing/info.png" alt="" />
            </div>
            <div className="flex items-end w-full h-24 px-8 py-6 border-b border-r border-gray-900">
              <p className="text-xl min-w-max font-medium text-white">Dropbox core features</p>
            </div>
            <div className="flex items-center w-full h-16 px-8 py-5 border-b border-r border-gray-900">
              <p className="mr-3.5 min-w-max text-white">Shared link controls</p>
              <img src="nightsable-assets/images/pricing/info.png" alt="" />
            </div>
            <div className="flex items-center w-full h-16 px-8 py-5 border-b border-r border-gray-900">
              <p className="mr-3.5 min-w-max text-white">External sharing controls and reporting</p>
              <img src="nightsable-assets/images/pricing/info.png" alt="" />
            </div>
            <div className="flex items-center w-full h-16 px-8 py-5 border-b border-r border-gray-900">
              <p className="mr-3.5 min-w-max text-white">Alerts and notifications</p>
              <img src="nightsable-assets/images/pricing/info.png" alt="" />
            </div>
            <div className="flex items-center w-full h-16 px-8 py-5 border-b border-r border-gray-900">
              <p className="mr-3.5 min-w-max text-white">Ransomware detection and recovery</p>
              <img src="nightsable-assets/images/pricing/info.png" alt="" />
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="flex flex-wrap">
            <div className="w-full border-b border-r border-gray-900" style={{height: 240}}>
              <div className="p-12">
                <h3 className="mb-3 min-w-max text-xl text-white font-medium">Basic</h3>
                <p className="mb-7 min-w-max text-white font-medium text-5xl">
                  <span>$10</span>
                  <span className="text-base font-medium text-gray-300">/ month</span>
                </p>
                <a className="inline-block min-w-max px-16 py-2.5 text-center font-light tracking-2xl border-2 border-green-400 bg-green-400 hover:bg-green-500 text-black focus:ring-4 focus:ring-green-500 focus:ring-opacity-40 rounded-full transition duration-300" href="#">Buy now</a>
              </div>
            </div>
            <div className="w-full h-24 px-8 py-6 border-b border-r border-gray-900" />
            <div className="flex items-center w-full h-16 px-8 py-5 border-b border-r border-gray-900">
              <p className="min-w-max text-white mx-auto">3,000 gb</p>
            </div>
            <div className="flex items-center w-full h-16 px-8 py-5 border-b border-r border-gray-900">
              <p className="min-w-max text-white mx-auto">1 user</p>
            </div>
            <div className="flex items-center w-full h-16 px-8 py-5 border-b border-r border-gray-900">
              <p className="flex items-center justify-center min-w-max mx-auto w-5 h-5 border border-green-400 rounded-full">
                <img src="nightsable-assets/images/pricing/check.svg" alt="" />
              </p>
            </div>
            <div className="flex items-center w-full h-16 px-8 py-5 border-b border-r border-gray-900">
              <p className="flex items-center justify-center min-w-max mx-auto w-5 h-5 border border-green-400 rounded-full">
                <img src="nightsable-assets/images/pricing/check.svg" alt="" />
              </p>
            </div>
            <div className="flex items-center w-full h-16 px-8 py-5 border-b border-r border-gray-900" />
            <div className="flex items-center w-full h-16 px-8 py-5 border-b border-r border-gray-900" />
            <div className="flex items-end w-full h-24 px-8 py-6 border-b border-r border-gray-900" />
            <div className="flex items-center w-full h-16 px-8 py-5 border-b border-r border-gray-900" />
            <div className="flex items-center w-full h-16 px-8 py-5 border-b border-r border-gray-900" />
            <div className="flex items-center w-full h-16 px-8 py-5 border-b border-r border-gray-900" />
            <div className="flex items-center w-full h-16 px-8 py-5 border-b border-r border-gray-900" />
          </div>
        </div>
        <div className="flex-1">
          <div className="flex flex-wrap">
            <div className="w-full bg-gray-900 bg-opacity-30 border-b border-r border-gray-900" style={{height: 240}}>
              <div className="p-12">
                <h3 className="mb-3 min-w-max text-xl text-white font-medium">Business</h3>
                <p className="mb-7 min-w-max text-white font-medium text-5xl">
                  <span>$20</span>
                  <span className="text-base font-medium text-gray-300">/ month</span>
                </p>
                <a className="inline-block min-w-max px-16 py-2.5 text-center font-light tracking-2xl border-2 border-green-400 bg-green-400 hover:bg-green-500 text-black focus:ring-4 focus:ring-green-500 focus:ring-opacity-40 rounded-full transition duration-300" href="#">Buy now</a>
              </div>
            </div>
            <div className="w-full bg-gray-900 bg-opacity-30 h-24 px-8 py-6 border-b border-r border-gray-900" />
            <div className="flex items-center w-full bg-gray-900 bg-opacity-30 h-16 px-8 py-5 border-b border-r border-gray-900">
              <p className="min-w-max text-white mx-auto">3,000 gb</p>
            </div>
            <div className="flex items-center w-full bg-gray-900 bg-opacity-30 h-16 px-8 py-5 border-b border-r border-gray-900">
              <p className="min-w-max text-white mx-auto">3+ users</p>
            </div>
            <div className="flex items-center w-full bg-gray-900 bg-opacity-30 h-16 px-8 py-5 border-b border-r border-gray-900">
              <p className="flex items-center justify-center min-w-max mx-auto w-5 h-5 border border-green-400 rounded-full">
                <img src="nightsable-assets/images/pricing/check.svg" alt="" />
              </p>
            </div>
            <div className="flex items-center w-full bg-gray-900 bg-opacity-30 h-16 px-8 py-5 border-b border-r border-gray-900">
              <p className="flex items-center justify-center min-w-max mx-auto w-5 h-5 border border-green-400 rounded-full">
                <img src="nightsable-assets/images/pricing/check.svg" alt="" />
              </p>
            </div>
            <div className="flex items-center w-full bg-gray-900 bg-opacity-30 h-16 px-8 py-5 border-b border-r border-gray-900">
              <p className="flex items-center justify-center min-w-max mx-auto w-5 h-5 border border-green-400 rounded-full">
                <img src="nightsable-assets/images/pricing/check.svg" alt="" />
              </p>
            </div>
            <div className="flex items-center w-full bg-gray-900 bg-opacity-30 h-16 px-8 py-5 border-b border-r border-gray-900">
              <p className="flex items-center justify-center min-w-max mx-auto w-5 h-5 border border-green-400 rounded-full">
                <img src="nightsable-assets/images/pricing/check.svg" alt="" />
              </p>
            </div>
            <div className="flex items-end w-full bg-gray-900 bg-opacity-30 h-24 px-8 py-6 border-b border-r border-gray-900" />
            <div className="flex items-center w-full bg-gray-900 bg-opacity-30 h-16 px-8 py-5 border-b border-r border-gray-900">
              <p className="flex items-center justify-center min-w-max mx-auto w-5 h-5 border border-green-400 rounded-full">
                <img src="nightsable-assets/images/pricing/check.svg" alt="" />
              </p>
            </div>
            <div className="flex items-center w-full bg-gray-900 bg-opacity-30 h-16 px-8 py-5 border-b border-r border-gray-900">
              <p className="flex items-center justify-center min-w-max mx-auto w-5 h-5 border border-green-400 rounded-full">
                <img src="nightsable-assets/images/pricing/check.svg" alt="" />
              </p>
            </div>
            <div className="flex items-center w-full bg-gray-900 bg-opacity-30 h-16 px-8 py-5 border-b border-r border-gray-900">
              <p className="flex items-center justify-center min-w-max mx-auto w-5 h-5 border border-green-400 rounded-full">
                <img src="nightsable-assets/images/pricing/check.svg" alt="" />
              </p>
            </div>
            <div className="flex items-center w-full bg-gray-900 bg-opacity-30 h-16 px-8 py-5 border-b border-r border-gray-900">
              <p className="flex items-center justify-center min-w-max mx-auto w-5 h-5 border border-green-400 rounded-full">
                <img src="nightsable-assets/images/pricing/check.svg" alt="" />
              </p>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="flex flex-wrap">
            <div className="w-full border-b border-gray-900" style={{height: 240}}>
              <div className="p-12">
                <h3 className="mb-3 min-w-max text-xl text-white font-medium">Premium</h3>
                <p className="mb-7 min-w-max text-white font-medium text-5xl">
                  <span>$50</span>
                  <span className="text-base font-medium text-gray-300">/ month</span>
                </p>
                <a className="inline-block min-w-max px-16 py-2.5 text-center font-light tracking-2xl border-2 border-green-400 bg-green-400 hover:bg-green-500 text-black focus:ring-4 focus:ring-green-500 focus:ring-opacity-40 rounded-full transition duration-300" href="#">Buy now</a>
              </div>
            </div>
            <div className="w-full h-24 px-8 py-6 border-b border-r border-gray-900" />
            <div className="flex items-center w-full h-16 px-8 py-5 border-b border-r border-gray-900">
              <p className="min-w-max text-white mx-auto">3,000 gb</p>
            </div>
            <div className="flex items-center w-full h-16 px-8 py-5 border-b border-r border-gray-900">
              <p className="min-w-max text-white mx-auto">3+ users</p>
            </div>
            <div className="flex items-center w-full h-16 px-8 py-5 border-b border-r border-gray-900">
              <p className="flex items-center justify-center min-w-max mx-auto w-5 h-5 border border-green-400 rounded-full">
                <img src="nightsable-assets/images/pricing/check.svg" alt="" />
              </p>
            </div>
            <div className="flex items-center w-full h-16 px-8 py-5 border-b border-r border-gray-900">
              <p className="flex items-center justify-center min-w-max mx-auto w-5 h-5 border border-green-400 rounded-full">
                <img src="nightsable-assets/images/pricing/check.svg" alt="" />
              </p>
            </div>
            <div className="flex items-center w-full h-16 px-8 py-5 border-b border-r border-gray-900">
              <p className="flex items-center justify-center min-w-max mx-auto w-5 h-5 border border-green-400 rounded-full">
                <img src="nightsable-assets/images/pricing/check.svg" alt="" />
              </p>
            </div>
            <div className="flex items-center w-full h-16 px-8 py-5 border-b border-r border-gray-900">
              <p className="flex items-center justify-center min-w-max mx-auto w-5 h-5 border border-green-400 rounded-full">
                <img src="nightsable-assets/images/pricing/check.svg" alt="" />
              </p>
            </div>
            <div className="flex items-end w-full h-24 px-8 py-6 border-b border-r border-gray-900" />
            <div className="flex items-center w-full h-16 px-8 py-5 border-b border-r border-gray-900">
              <p className="flex items-center justify-center min-w-max mx-auto w-5 h-5 border border-green-400 rounded-full">
                <img src="nightsable-assets/images/pricing/check.svg" alt="" />
              </p>
            </div>
            <div className="flex items-center w-full h-16 px-8 py-5 border-b border-r border-gray-900">
              <p className="flex items-center justify-center min-w-max mx-auto w-5 h-5 border border-green-400 rounded-full">
                <img src="nightsable-assets/images/pricing/check.svg" alt="" />
              </p>
            </div>
            <div className="flex items-center w-full h-16 px-8 py-5 border-b border-r border-gray-900">
              <p className="flex items-center justify-center min-w-max mx-auto w-5 h-5 border border-green-400 rounded-full">
                <img src="nightsable-assets/images/pricing/check.svg" alt="" />
              </p>
            </div>
            <div className="flex items-center w-full h-16 px-8 py-5 border-b border-r border-gray-900">
              <p className="flex items-center justify-center min-w-max mx-auto w-5 h-5 border border-green-400 rounded-full">
                <img src="nightsable-assets/images/pricing/check.svg" alt="" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

