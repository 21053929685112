import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import PricingSectionNavigations1 from '../components/navigations/PricingSectionNavigations1';
import PricingSectionPricing2 from '../components/pricing/PricingSectionPricing2';
import PricingSectionFooters3 from '../components/footers/PricingSectionFooters3';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Pricing() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <PricingSectionNavigations1 />
      <PricingSectionPricing2 />
      <PricingSectionFooters3 />
    </React.Fragment>
  );
}

