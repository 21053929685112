import React from 'react';

export default function MissionSectionNavigations1() {
  return (
    <React.Fragment>
      <>
        <section>
          <div className="container px-4 mx-auto">
            <div className="flex items-center justify-between pt-5 pb-2.5 -m-2">
              <div className="w-auto p-2">
                <div className="flex flex-wrap items-center">
                  <div className="w-auto">
                  <a className="relative z-10 inline-block font-['Roboto'] text-xl italic font-thin" href="/">
                      Aexy.io
                    </a>
                  </div>
                </div>
              </div>
              <div className="w-auto p-2">
                <div className="flex flex-wrap items-center">
                  <div className="w-auto hidden lg:block">
                    <ul className="flex items-center mr-12">
                      <li className="mr-12 text-white font-medium hover:text-opacity-90 tracking-tighter"><a href="/">Home</a></li>
                      <li className="mr-12 text-white font-medium hover:text-opacity-90 tracking-tighter"><a href="/mission">Mission</a></li>
                      <li className="mr-12 text-white font-medium hover:text-opacity-90 tracking-tighter"><a href="/#products">Products</a></li>
                      {/* <li className="text-white font-medium hover:text-opacity-90 tracking-tighter"><a href="/ai-workforce/10X">10X AI Agent</a></li> */}
                    </ul>
                  </div>
                  <div className="w-auto hidden lg:block">
                    <div className="inline-block"><a className="inline-block px-8 py-4 text-white hover:text-black tracking-tighter hover:bg-green-400 border-2 border-white focus:border-green-400 focus:border-opacity-40 hover:border-green-400 focus:ring-4 focus:ring-green-400 focus:ring-opacity-40 rounded-full transition duration-300" href="#">Get in touch</a></div>
                  </div>
                  <div className="w-auto lg:hidden">
                    <a className="relative z-10 inline-block" href="#">
                      <svg className="navbar-burger text-green-500" width={51} height={51} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width={56} height={56} rx={28} fill="currentColor" />
                        <path d="M37 32H19M37 24H19" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden navbar-menu fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-50">
            <div className="navbar-backdrop fixed inset-0 bg-black opacity-80" />
            <nav className="relative z-10 px-9 pt-8 h-full bg-black overflow-y-auto">
              <div className="flex flex-wrap justify-between h-full">
                <div className="w-full">
                  <div className="flex items-center justify-between -m-2">
                    <div className="w-auto p-2">
                    <a className="relative z-10 inline-block font-['Roboto'] text-xl italic font-thin" href="/">
                      Aexy.io
                    </a>
                    </div>
                    <div className="w-auto p-2">
                      <a className="navbar-burger inline-block text-white" href="#">
                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6 18L18 6M6 6L18 18" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-center py-16 w-full">
                  <ul>
                    <li className="mb-8 text-white font-medium hover:text-opacity-90 tracking-tighter"><a href="/">Home</a></li>
                    <li className="mb-8 text-white font-medium hover:text-opacity-90 tracking-tighter"><a href="/mission">Mission</a></li>
                    {/* <li className="mb-8 text-white font-medium hover:text-opacity-90 tracking-tighter"><a href="/ai-text-to-video-editor">Aexy AI Video Editor</a></li> */}
                    <li className="mb-8 text-white font-medium hover:text-opacity-90 tracking-tighter"><a href="/#products">Products</a></li>
                  </ul>
                </div>
                <div className="flex flex-col justify-end w-full pb-8"><a className="inline-block px-8 py-4 text-center text-white hover:text-black tracking-tighter hover:bg-green-400 border-2 border-white focus:border-green-400 focus:border-opacity-40 hover:border-green-400 focus:ring-4 focus:ring-green-400 focus:ring-opacity-40 rounded-full transition duration-300" href="#">Get in touch</a></div>
              </div>
            </nav>
          </div>
        </section>


      </>
    </React.Fragment>
  );
}

