import React from 'react';

export default function IndexSectionAbout5() {
  return (
    <React.Fragment>
      <>
        <section className="pt-20 pb-24 md:pb-28 overflow-hidden">
          <div className="container px-4 mx-auto">
            <div className="flex flex-wrap -m-8">
              <div className="w-full md:w-1/2 p-8">
                <div className="md:max-w-md">
                  <span className="inline-block mb-4 text-sm text-green-400 font-medium tracking-tighter">Let's do it together</span>
                  <h1 className="text-6xl md:text-7xl text-white tracking-tighter-xl">Move the world in a better direction.</h1>
                </div>
              </div>
              <div className="w-full md:w-1/2 p-8">
                <h3 className="mb-8 text-2xl text-white tracking-tighter">We are building tools that will change the society. Come join us so you can be a part of that journey.</h3>
                <p className="mb-10 text-lg text-gray-300">We are always looking for mission driven, passionate individuals who can get things done without shitting on others.
                  We are about to bring a cultural change in society, so we need good people who believe that change is possible. </p>
                <a className="inline-block px-8 py-4 text-white hover:text-black tracking-tighter hover:bg-green-400 border-2 border-white focus:border-green-400 focus:border-opacity-40 hover:border-green-400 focus:ring-4 focus:ring-green-400 focus:ring-opacity-40 rounded-full transition duration-300" href="#">Work at Aexy</a>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

