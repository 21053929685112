import React from 'react';

export default function IndexSectionFeatures3() {
  return (
    <React.Fragment>
      <>
        <section className="relative py-24 overflow-hidden">
          <div className="container px-4 mx-auto">
            <div className="mb-20 md:max-w-xl text-center mx-auto">
              <span className="inline-block mb-4 text-sm text-green-400 font-medium tracking-tighter">AI Research Lab</span>
              <h2 className="text-7xl lg:text-8xl text-white tracking-tighter-xl" id='products'> Our Products</h2>
            </div>
            <div className="flex flex-wrap -m-4">
              <div className="w-full md:w-1/2 p-4">
                <div className="px-10 pt-14 pb-12 h-full bg-gradient-radial-dark border border-gray-900 border-opacity-30 rounded-3xl">
                  <a className="inline-block mb-6 px-5 py-3 text-sm text-white hover:text-black font-medium hover:bg-green-500 tracking-tight border-2 border-white hover:border-green-500 focus:ring-4 focus:ring-green-500 focus:ring-opacity-40 rounded-full transition duration-200" href="https://calendly.com/bhanuc">Join the Beta</a>
                  <h3 className="mb-6 text-4xl text-white tracking-tighter-xl">AI Text to Video Editor</h3>
                  <p className="mb-40 text-white">AI Native Text to Video Editor that allows user to take his story from a paragraph to a feature film length video in few days instead of years.</p>
                  <a className="inline-block" href="#">
                    <svg width={26} height={26} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M24.1689 2L2.11268 24" stroke="white" strokeWidth={3} strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M24.1689 22.1732V2H3.94413" stroke="white" strokeWidth={3} strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="w-full md:w-1/2 p-4">
                <div className="px-10 pt-14 pb-12 h-full bg-gradient-radial-dark border border-gray-900 border-opacity-30 rounded-3xl">
                  <a className="inline-block mb-6 px-5 py-3 text-sm text-white hover:text-black font-medium hover:bg-green-500 tracking-tight border-2 border-white hover:border-green-500 focus:ring-4 focus:ring-green-500 focus:ring-opacity-40 rounded-full transition duration-200" href="https://calendly.com/bhanuc">Join the Beta</a>
                  <h3 className="mb-6 text-4xl text-white tracking-tighter-xl">Homie</h3>
                  <p className="mb-40 text-white">Homie helps you automate and execute any task that can and should be automated. </p>
                  <a className="inline-block" href="#">
                    <svg width={26} height={26} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M24.1689 2L2.11268 24" stroke="white" strokeWidth={3} strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M24.1689 22.1732V2H3.94413" stroke="white" strokeWidth={3} strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

