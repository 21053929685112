import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import MissionSectionNavigations1 from '../components/navigations/MissionSectionNavigations1';
import MissionSectionBlogContent3 from '../components/blog-content/MissionSectionBlogContent3';
import MissionSectionAbout2 from '../components/about/MissionSectionAbout2';

const meta = {
  title: 'Our Mission is to actually change the world.',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Mission() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <MissionSectionNavigations1 />
      <MissionSectionBlogContent3 />
      <MissionSectionAbout2 />
    </React.Fragment>
  );
}

